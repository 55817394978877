import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";

import OnboardingForm from "../../library/ui_custom/onboarding_form";

type FormValues = {
  coreValues: string;
  lifeGoals: string;
  dealbreakers: string;
};

const questions = [
  {
    id: "core_values",
    question: "What are your personal core values?",
    placeholder: "E.g., growth, independence, accountability, service...",
  },
  {
    id: "life_goals",
    question: "What are your life goals?",
    placeholder:
      "E.g., master [X] skill, build a fulfilling career, find a life partner...",
  },
  {
    id: "dealbreakers",
    question: "What are your relationship dealbreakers?",
    placeholder:
      "E.g., possessiveness/jealousy, lack of hygiene, history of cheating...",
  },
];

export default function ActivelyDatingOnboardingScreen() {
  let navigate = useNavigate();

  const routeToHomepage = () => {
    let path = `/user_app/home`;
    navigate(path);
  };

  const mutation = useMutation(async (data: FormValues) => {
    const res = await axios.post(
      "/api/user_app/self_dating_reflection/create",
      {
        ...data,
      }
    );

    if (res.data.datingReflectionId) {
      routeToHomepage();
    }
  });

  return (
    <OnboardingForm
      questions={questions}
      handleSubmit={(data: any) => mutation.mutateAsync(data)}
    />
  );
}
