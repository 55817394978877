import {
  Avatar,
  Box,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
  chakra,
} from "@chakra-ui/react";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useContext } from "react";

import { UserContext } from "../../user_app/app";
import { useIsMobile } from "../../user_app/hooks/use_is_mobile";
import Sidebar, { LinkItemProps } from "./sidebar";

const StyledNameText = chakra(Text, {
  baseStyle: {
    color: "white",
  },
});

export default function NavBar({
  onLogout,
  onSidebarToggle,
  isSidebarOpen,
  linkItems,
  children,
}: {
  onLogout: () => void;
  onSidebarToggle: () => void;
  isSidebarOpen: boolean;
  linkItems: Array<LinkItemProps>;
  children: React.ReactNode;
}) {
  const userProfile = useContext(UserContext);
  const fullName = `${userProfile?.firstName} ${userProfile?.lastName}`;
  const isMobile = useIsMobile();

  return (
    <Box bg={"white"}>
      <Flex
        bg={"darkMoss.500"}
        h={16}
        alignItems={"center"}
        justifyContent={"space-between"}
        position={"sticky"}
        top={0}
        zIndex={300}
      >
        <HStack spacing={8} alignItems={"center"}>
          {isSidebarOpen && isMobile ? (
            <IconButton
              onClick={onSidebarToggle}
              size={"md"}
              icon={<CloseIcon />}
              aria-label={"Close Menu"}
              marginLeft="2em"
            />
          ) : (
            <IconButton
              onClick={onSidebarToggle}
              size={"md"}
              icon={<MenuIcon />}
              aria-label={"Open Menu"}
              marginLeft="2em"
            />
          )}
        </HStack>
        <Flex alignItems={"center"}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{
                border: "solid 2px #4f5a51",
              }}
            >
              <HStack marginRight="2em">
                <Avatar size={"sm"} name={fullName} />
                <VStack
                  display={{ base: "none", md: "flex" }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <StyledNameText>{userProfile?.firstName}</StyledNameText>
                </VStack>
                <Box display={{ base: "none", md: "flex" }}>
                  <ExpandMoreIcon />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList borderColor="core.apple">
              {/* //TODO: Re-comment back in post-BETA
                <MenuItem>Profile Settings</MenuItem> */}
              <MenuItem data-umami-event="log-out-btn" onClick={onLogout}>
                Log out
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
      {isSidebarOpen ? (
        <Sidebar sidebarLinkItems={linkItems} onSidebarToggle={onSidebarToggle}>
          {children}
        </Sidebar>
      ) : (
        <>{children}</>
      )}
    </Box>
  );
}
