// Theme for ChakraUI, read more: https://chakra-ui.com/docs/styled-system/color-mode
// 1. import `extendTheme` function
import { type ThemeConfig, extendTheme } from "@chakra-ui/react";

import { buttonTheme } from "./component_themes/button_theme";
import { cardTheme } from "./component_themes/card_theme";
import { formErrorTheme } from "./component_themes/form_theme";
import { inputTheme } from "./component_themes/input_theme";
import { linkTheme } from "./component_themes/link_theme";
import { menuTheme } from "./component_themes/menu_theme";

// 2. Add your color mode config
const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const customTheme = {
  colors: {
    transparent: "transparent",
    black: "#000",
    white: "#fff",
    darkRed: "#9B2C2C",
    warning: "#F2DEAA",
    core: {
      sand: "#e2d3c3",
      chocolate: "#92846d",
      mint: "#9cd0a5",
      moss: "#6d9274",
      lavender: "#c49cd0",
      apple: "#DAF2C6",
    },
    lightLavender: "#f4eaf8",
    darkMoss: {
      50: "#86bb8f",
      100: "#78ac80",
      200: "#6b9b73",
      300: "#638669",
      400: "#57755c",
      500: "#536757",
      600: "#4f5a51",
      700: "#4b4d4b",
      800: "#454245",
      900: "#3f373e",
    },
    peach: {
      50: "#ffede4",
      100: "#f8ccba",
      200: "#f1ab8e",
      300: "#ec8961",
      400: "#e66834",
      500: "#cc4e1b",
      600: "#9f3d15",
      700: "#722b0e",
      800: "#451907",
      900: "#1b0600",
    },
    purple: {
      50: "#f8eff5",
      100: "#ded4dc",
      200: "#c5b8c4",
      300: "#af9cac",
      400: "#987f94",
      500: "#7e657b",
      600: "#624f60",
      700: "#473945",
      800: "#2b212a",
      900: "#110B11",
    },
  },
  styles: {
    global: {
      body: {
        bg: "white",
      },
    },
  },
  components: {
    Card: cardTheme,
    Button: buttonTheme,
    Link: linkTheme,
    Menu: menuTheme,
    FormError: formErrorTheme,
    Input: inputTheme,
  },
};

// 3. extend the theme
const theme = extendTheme({
  ...config,
  ...customTheme,
  fonts: {
    heading: "Istok Web",
    body: "Istok Web",
  },
});

export default theme;
