import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  Progress,
  Text,
  Textarea,
  chakra,
} from "@chakra-ui/react";
import { ArrowBack, ArrowForward, Send } from "@mui/icons-material";
import React, { useState } from "react";

const StyledButtonGroup = chakra("div", {
  baseStyle: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1em",
  },
});

const StyledButton = chakra(Button, {
  baseStyle: {
    display: "flex",
    gap: "0.5em",
  },
});

const OnboardingForm = ({
  questions,
  handleSubmit,
}: {
  questions: Array<{
    id: string;
    question: string;
    placeholder: string;
  }>;
  handleSubmit: (answers: { [key: string]: string }) => void;
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [answers, setAnswers] = useState<{ [key: string]: string }>({});

  const handleInputChange = (questionId: string, value: string) => {
    setAnswers((prev) => ({
      ...prev,
      [questionId]: value,
    }));
  };

  const handleNext = () => {
    if (currentStep < questions.length - 1) {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  const currentQuestion = questions[currentStep];
  const progress = ((currentStep + 1) / questions.length) * 100;

  return (
    <div>
      <Heading as="h1" size="md" mb={6}>
        Just a few more questions...
      </Heading>

      <Progress value={progress} colorScheme="green" marginTop="3em" />

      <Text marginTop="2em" marginBottom="0.5em">
        Question {currentStep + 1} of {questions.length}
      </Text>

      <FormControl>
        <FormLabel fontWeight="bold">{currentQuestion.question}</FormLabel>

        <Textarea
          placeholder={currentQuestion.placeholder}
          value={answers[currentQuestion.id] || ""}
          onChange={(e) =>
            handleInputChange(currentQuestion.id, e.target.value)
          }
          marginTop="1em"
        />
      </FormControl>

      {/* Navigation buttons */}
      <StyledButtonGroup>
        <StyledButton
          onClick={handlePrevious}
          isDisabled={currentStep === 0}
          variant="outline"
        >
          <ArrowBack />
          Previous
        </StyledButton>

        {currentStep === questions.length - 1 ? (
          <StyledButton onClick={() => handleSubmit(answers)} variant="outline">
            Complete
            <Send />
          </StyledButton>
        ) : (
          <StyledButton onClick={handleNext} variant="outline">
            Next
            <ArrowForward />
          </StyledButton>
        )}
      </StyledButtonGroup>
    </div>
  );
};

export default OnboardingForm;
