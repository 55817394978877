import { Button, ButtonGroup, Heading } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function StatusSelectionScreen() {
  let navigate = useNavigate();
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation(async (data: String) => {
    await axios.put("/api/user_app/profile", {
      relationshipStatus: data,
    });
    queryClient.invalidateQueries({ queryKey: ["user"] });
  });

  const routeChangeToActivelyDatingOnboardingFlow = async () => {
    let path = `/user_app/onboarding/dating`;

    try {
      await mutateAsync("ACTIVELY_DATING");
    } catch (error) {
      toast({
        title: "An error occurred.",
        description: "Unable to update relationship status.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    navigate(path);
  };

  const routeChangeToCommittedRelationshipOnboardingFlow = async () => {
    let path = `/user_app/onboarding/relationship`;

    try {
      await mutateAsync("COMMITTED_RELATIONSHIP");
    } catch (error) {
      toast({
        title: "An error occurred.",
        description: "Unable to update relationship status.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    navigate(path);
  };

  return (
    <div>
      <Heading as="h1" size="md" mb={6} textAlign="center">
        To get started, what's your current relationship status?
      </Heading>

      <ButtonGroup
        spacing={4}
        marginTop="2em"
        display="flex"
        justifyContent="center"
        flexFlow="row wrap"
      >
        <Button
          onClick={routeChangeToActivelyDatingOnboardingFlow}
          marginBottom="1em"
        >
          Actively Dating
        </Button>
        <Button onClick={routeChangeToCommittedRelationshipOnboardingFlow}>
          In a Committed Relationship
        </Button>
      </ButtonGroup>
    </div>
  );
}
