import { Box, Image } from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useRef } from "react";

import clari_mascot_talk from "../../../../assets/images/clari_mascot_talk.png";
import TypingIndicator from "../../library/ui_custom/typing_indicator";
import useCompanionDriver from "../hooks/use_companion_driver";
import { useIsMobile } from "../hooks/use_is_mobile";

const initialOutputs = [
  `Hi! I'm proud of you for taking time to journal. Your feelings are
      valid. I'll act as a sounding board,
      helping you explore and refine your thoughts.`,
  `Important: I am NOT a substitute for professional medical advice,
      diagnosis, or treatment. If you are experiencing a medical emergency,
      please call 911 or your healthcare provider immediately.`,
];

const limitReachedMessage = `Buzz buzz! You've reached your limit to access this sounding board. Don’t be(e) discouraged—if you want to continue to interact with this please contact us at help@claribee.com.`;

const AiCompanionContainer = ({
  inputText,
  canUseAiFeatures,
}: {
  inputText?: string;
  canUseAiFeatures: boolean;
}) => {
  const bottomRef = useRef<HTMLDivElement>(null);
  const isMobile = useIsMobile();

  const [companionOutputs, setCompanionOutputs] = React.useState<string[]>(
    canUseAiFeatures ? [...initialOutputs] : [limitReachedMessage]
  );

  const [companionResponding, setCompanionResponding] =
    React.useState<boolean>(false);

  const activateCompanion = async () => {
    if (!inputText || !canUseAiFeatures) {
      return;
    }

    try {
      setCompanionResponding(true);
      const response = await axios.post("/api/user_app/companions/create", {
        inputText: inputText,
      });
      setCompanionResponding(false);
      if (!canUseAiFeatures) {
        setCompanionOutputs([
          ...companionOutputs,
          response.data.companionResults,
          limitReachedMessage,
        ]);
      } else {
        setCompanionOutputs([
          ...companionOutputs,
          response.data.companionResults,
        ]);
      }
    } catch (error) {
      console.error("Error activating companion:", error);
    }
  };

  useEffect(() => {
    if (inputText) {
      setDriverInputText(inputText);
    }
  }, [inputText]);

  const [setDriverInputText] = useCompanionDriver({
    onCompanionTrigger: () => {
      activateCompanion();
    },
  });

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [companionOutputs]);

  return (
    <Box
      // Styles to make the floating avatar work
      position="relative"
      marginTop="25px"
      display="flex"
      flexDirection="column"
      padding="1em"
      flex={1}
      borderRadius="1em"
      backgroundImage="linear-gradient(#57755c, #6D9274)"
      shadow="md"
      marginBottom={isMobile ? "2em" : "0"}
    >
      <Image
        height="50px"
        position="absolute"
        top="-25px"
        left="0"
        right="0"
        margin="auto"
        src={clari_mascot_talk}
        alt="Clari the mascot"
      />
      {/* Keeping this button here for now for testing purposes */}
      {/* <button
        onClick={() => {
          setCompanionOutputs([...companionOutputs, "Hello world"]);
        }}
      >
        Click mee
      </button> */}
      <Box
        borderRadius="0.5em"
        marginBottom="1em"
        height="20em"
        overflow="auto"
        flex={1}
      >
        {companionOutputs.map((output) => (
          <Box
            key={output}
            padding="1em"
            backgroundColor="peach.50"
            borderRadius="0.5em"
            marginBottom="1em"
          >
            {output}
          </Box>
        ))}
        <div ref={bottomRef} />
      </Box>
      {companionResponding && <TypingIndicator />}
      <Box padding="1em" backgroundColor="pink" borderRadius="0.5em">
        <p>
          <strong>DISCLAIMER:</strong> Claribee is not a substitute for
          professional medical advice, diagnosis, or treatment. If you are
          experiencing a medical emergency, please call 911 or your healthcare
          provider immediately.
        </p>
      </Box>
    </Box>
  );
};

export default AiCompanionContainer;
