import {
  Box,
  Button,
  Card,
  Heading,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  chakra,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import React from "react";
import { useSearchParams } from "react-router-dom";

import RichTextArea from "../../library/ui_custom/rich_text_area";
import { useIsMobile } from "../hooks/use_is_mobile";

const StyledCard = chakra(Card, {
  baseStyle: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "1em",
    padding: 0,
  },
});

interface PrivateJournalEntriesProps {
  id: number;
  title: string;
  truncatedContent: string;
  createdAt: Date;
  summaryText?: string;
  onDeleteClicked: (id: number) => void;
}

export default function PrivateJournalEntryCard({
  id,
  title,
  truncatedContent,
  createdAt,
  summaryText,
  onDeleteClicked,
}: PrivateJournalEntriesProps) {
  const isMobile: boolean = useIsMobile();
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <StyledCard flexDirection={isMobile ? "column" : "row"}>
      <Popover trigger="hover" placement={isMobile ? "top" : "left"}>
        <PopoverTrigger>
          <Box
            display="flex"
            flex={1}
            flexDirection="column"
            padding="1.5em"
            gap="1em"
          >
            <Box display="flex" flexDirection="row" alignItems="center">
              <Heading as="h2" size="sm">
                {title}{" "}
              </Heading>
              <Text marginLeft="1em" fontSize="sm">
                {dayjs(createdAt).format("MMM D, YYYY")}
              </Text>
            </Box>
            <RichTextArea
              editable={false}
              initialEditableContainer={false}
              value={truncatedContent}
            />
          </Box>
        </PopoverTrigger>
        {summaryText && (
          <PopoverContent width="15em">
            <PopoverArrow />
            <PopoverHeader>
              <Heading as="h2" size="sm">
                AI Summary
              </Heading>
            </PopoverHeader>
            <PopoverBody>{summaryText}</PopoverBody>
          </PopoverContent>
        )}
      </Popover>
      <Box
        display="flex"
        flexDirection="column"
        borderLeft={isMobile ? "none" : "1px solid"}
        borderLeftColor={isMobile ? "none" : "core.sand"}
        padding="1.5em"
        gap="1em"
      >
        <Button
          data-umami-event="view-edit-journal-note-btn"
          onClick={() => {
            searchParams.set("private_journal_entry_id", `${id}`);
            setSearchParams(searchParams);
          }}
        >
          View/Edit
        </Button>
        <Button
          data-umami-event="delete-journal-note-btn"
          variant="outline"
          onClick={() => {
            onDeleteClicked(id);
          }}
        >
          Delete
        </Button>
      </Box>
    </StyledCard>
  );
}
